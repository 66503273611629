import { ReportHandler } from 'web-vitals';

type ReportWebVitals = (ononPerfEntry?: ReportHandler) => void

const reportWebVitals: ReportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals')
      .then(({
        getCLS, getFID, getFCP, getLCP, getTTFB,
      }) => {
        getCLS(onPerfEntry);
        getFID(onPerfEntry);
        getFCP(onPerfEntry);
        getLCP(onPerfEntry);
        getTTFB(onPerfEntry);
      })
      // eslint-disable-next-line no-console
      .catch((err) => { console.log('reportWebVitals: ', err); });
  }
};

export default reportWebVitals;
